// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    domainRule,
    intMinRule,
    requiredRule,
    validate,
} from 'common/validator';
import * as computeResourceVmActions from 'admin/computeResource/actions/vms';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { ISelectOption } from 'common/components';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import SelectInput from 'common/components/Select/SelectInput';
import {
    GenerateButton,
    InlineFields,
    NameInputContainer,
} from 'admin/computeResource/page/containers/dialogs/ServerCreateDialog/Styles';
import { generateName } from 'common/helpers/vm';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { Button } from 'admin/common/components/Button/Button';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import { IProjectResponse } from 'common/api/resources/Project';
import * as userProjectsActions from 'admin/user/actions/projects';
import { osImages } from 'common/api/resources/OsImage';
import { plans } from 'common/api/resources/Plan';
import { sshKeys } from 'common/api/resources/SshKey';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import {
    getHostnameTemplate,
    shouldRegisterFqdnOnServerCreate,
} from 'common/modules/settings/selectors';
import { IVmCreateRequest } from 'common/api/resources/ComputeResource';
import { PlanDescription } from 'common/components/plan/components/PlanDescription/PlanDescription';

interface IServerCreateDialogProps {
    onClose: () => void;
}

export type ServerCreateDialogProps =
    IServerCreateDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const defaultValuesOfServer: IVmCreateRequest = Object.freeze({
    name: '',
    description: '',
    plan_id: 0,
    os_image_version_id: 0,
    password: '',
    ssh_keys: [],
    user_id: 0,
    project_id: 0,
});

export const ServerCreateDialog: React.FC<ServerCreateDialogProps> = ({
    onClose,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    permissions: {
        canGetOsImages,
        canGetSshKeys,
        canGetUsers,
    },
    computeResourceVmActions: { createComputeResourceVm },
    computeResourceId,
    formErrors,
    isItemSaving,
    userProjectsActions: { getProjects },
    user,
    projects,
    nameTemplate,
    generateHostname,
    registerFqdn,
    defaultValues,
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        ...defaultValues,
        name: generateHostname(nameTemplate),
        user_id: user.id,
    });
    const [showSshKeys, setShowSshKeys] = React.useState(true);
    const [isProjectLoading, setIsProjectLoading] = React.useState(true);

    React.useEffect(() => {
        const defaultProject = projects.data.find((item: IProjectResponse) => item.is_default);
        if (defaultProject !== undefined) {
            setSubmitValues({
                ...submitValues,
                project_id: defaultProject.id,
            });
        }
        setIsProjectLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    React.useEffect(() => {
        setSubmitValues(values => ({
            ...values,
            name: generateHostname(nameTemplate),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameTemplate]);

    React.useEffect(() => {
        loadProjects(user.id);

        return () => {
            clearFormErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadProjects = (userId: number) => {
        setIsProjectLoading(true);
        getProjects(userId, {
            filters: {
                owner_id: userId,
            },
        });
    };

    const handleSubmit = async (values: IVmCreateRequest) => {
        const rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            plan_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            os_image_version_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            password: requiredRule(<Translate content="validate.fieldRequired" />),
            user_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            project_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
        };

        if (registerFqdn) {
            rules.name = domainRule(<Translate content="validate.badDomain" />);
        }

        const errors = validate<IVmCreateRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            if (registerFqdn) {
                values.fqdns = [values.name];
            }

            await createComputeResourceVm(computeResourceId, values);

            onClose();
        } catch (e) {
            throw e;
        }
    };

    const projectOptions = React.useMemo(() => projects.data.map((item: IProjectResponse) => ({
        label: item.name,
        value: item.id,
    })), [projects]);

    const handleFieldChange = (field: string, value: string) => {
        setSubmitValues(setIn(submitValues, field, value));
    };

    const handleGenerateButtonClick = () => {
        setSubmitValues((state) => ({
            ...state,
            name: generateHostname(nameTemplate),
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePlanChange = (option: any) => {
        setSubmitValues(values => ({
            ...values,
            plan_id: option.value,
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSshKeyChange = (options: any) => {
        setSubmitValues(values => ({
            ...values,
            ssh_keys: options !== null ? options.map((option: ISelectOption) => option.value) : [],
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOsImageVersionChange = (option: any) => {
        setShowSshKeys(option.useSsh);
        setSubmitValues(values => ({
            ...values,
            ssh_keys: option.useSsh ? submitValues.ssh_keys : [],
            os_image_version_id: option.value,
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserChange = (option: any) => {
        if (option !== null) {
            setSubmitValues(values => ({
                ...values,
                user_id: option.value,
                project_id: 0,
            }));
            loadProjects(option.value);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleProjectChange = (option: any) => {
        setSubmitValues(values => ({
            ...values,
            project_id: option.value,
        }));
    };

    const loadSshKeyOptions = createOptionsLoader(
        sshKeys.list,
        (sshKey) => ({
            label: sshKey.name,
            value: sshKey.id,
        }),
        canGetSshKeys
    );

    const loadPlanOptions = createOptionsLoader(
        (params) => plans.list({ ...params, additional_ips_count: 0 }),
        (plan) => ({
            label: (
                <PlanDescription
                    planName={plan.name}
                    vcpu={plan.params.vcpu}
                    disk={plan.params.disk}
                    ram={plan.params.ram}
                />
            ),
            value: plan.id,
        })
    );

    const loadOsImagesOptions = createOptionsLoader(
        osImages.list,
        (image) => ({
            label: image.name,
            options: image.versions.map((version) => ({
                label: version.version,
                value: version.id,
                useSsh: version.is_ssh_keys_supported,
            })),
        }),
        canGetOsImages
    );

    const loadUserOptions = createOptionsLoader(
        users.list,
        (item: IUserResponse) => ({
            label: item.email,
            value: item.id,
        }),
        canGetUsers
    );

    const projectOption = () => {
        const option = projectOptions.find((item) => item.value === submitValues.project_id);

        if (option === undefined) {
            // @ts-ignore
            return null;
        }

        return option;
    };

    return (
        <Form
            id="vmForm"
            footerClassName="hidden"
            onSubmit={handleSubmit}
            values={submitValues}
            errors={formErrors}
            submitButton={false}
            cancelButton={false}
            applyButton={false}
            hideRequiredLegend={true}
            vertical={true}
            onFieldChange={handleFieldChange}
        >
            <Section>
                <NameInputContainer>
                    <FormFieldText
                        name="name"
                        size={SIZE.FILL}
                        label={<Translate content="computeResource.servers.form.name" />}
                        required={true}
                    />
                    <GenerateButton
                        ghost={true}
                        icon={ICONS.REFRESH}
                        onClick={handleGenerateButtonClick}
                    />
                </NameInputContainer>
                <FormField
                    name="os_image_version_id"
                    required={true}
                    label={<Translate content="computeResource.servers.form.osImageVersion" />}
                >
                    {({ getId }) => (
                        <AsyncSelectInput
                            inputId={getId()}
                            menuPosition="fixed"
                            loadOptions={loadOsImagesOptions}
                            onChange={handleOsImageVersionChange}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                        />
                    )}
                </FormField>
                <InlineFields>
                    <FormField
                        name="plan_id"
                        required={true}
                        label={<Translate content="computeResource.servers.form.plans" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                loadOptions={loadPlanOptions}
                                onChange={handlePlanChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                            />
                        )}
                    </FormField>
                    {/* hack for disabling plan field autocomplete */}
                    <input style={{ width: 0, border: 'unset', display: 'none', userSelect: 'none' }} />
                    <FormFieldPassword
                        size={SIZE.MD}
                        name="password"
                        label={<Translate content="computeResource.servers.form.password" />}
                        required={true}
                    />
                </InlineFields>
                {showSshKeys && (
                    <FormField name="ssh_keys" label={<Translate content="computeResource.servers.form.sshKeys" />}>
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                isMulti={true}
                                loadOptions={loadSshKeyOptions}
                                onChange={handleSshKeyChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                            />
                        )}
                    </FormField>
                )}
                <InlineFields>
                    <FormField
                        name="user_id"
                        required={true}
                        label={<Translate content="computeResource.servers.form.user" />}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                loadOptions={loadUserOptions}
                                onChange={handleUserChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                defaultValue={{
                                    value: user.id,
                                    label: user.email,
                                }}
                            />
                        )}
                    </FormField>
                    <FormField
                        name="project_id"
                        required={true}
                        label={<Translate content="computeResource.servers.form.project" />}
                    >
                        {({ getId }) => (
                            <SelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                options={projectOptions}
                                onChange={handleProjectChange}
                                isDisabled={projectOptions.length === 0}
                                value={projectOption()}
                                isLoading={isProjectLoading}
                            />
                        )}
                    </FormField>
                </InlineFields>
            </Section>
            <Button
                type="submit"
                form="vmForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="computeResource.servers.form.saveBtn" />
            </Button>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => ({
    nameTemplate: getHostnameTemplate(state),
    generateHostname: generateName,
    registerFqdn: shouldRegisterFqdnOnServerCreate(state),
    computeResourceId: state.computeResource.item.id,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM),
    formErrors: getProcessedErrors(state),
    permissions: {
        canGetOsImages: hasPermission(state, PERMISSION_LIST.MANAGE_OS_IMAGES, PERMISSION_LIST.GET_OS_IMAGES),
        canGetSshKeys: hasPermission(state, PERMISSION_LIST.MANAGE_SSH_KEYS),
        canGetUsers: hasPermission(state, PERMISSION_LIST.MANAGE_USERS),
    },
    user: state.auth.user,
    projects: state.project.list,
    defaultValues: defaultValuesOfServer,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    userProjectsActions: bindActionCreators(userProjectsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerCreateDialog);
